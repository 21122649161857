var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-6"},[(_vm.if_roles_confirm)?_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.company_is_cert},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"accent darken-4","depressed":"","disabled":!_vm.company_is_cert},on:{"click":_vm.open_new_point_dialog}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить точку продаж ")],1)],1)]}}],null,false,1154538355)},[_vm._v(" Добавление точки продаж доступно только для сертифицированных партнеров ")]):_vm._e(),_c('ClearLocalStorage',{attrs:{"default_cols":_vm.cols_default,"storage_name":_vm.cols_storage_name,"cols_list":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols_default=$event}}})],1),_vm._l((_vm.updated_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{attrs:{"headers":_vm.updated_cols,"items":_vm.sale_points,"loading":_vm.loading,"no-data-text":"Точки продаж отсутствуют"},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? 'Включено' : 'Выключено')+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.address)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.address)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.address))])],1)]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",class:_vm.$style.min_width},_vm._l((item.telephone),function(phone){return _c('div',{key:phone.id},[_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(phone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]),_c('span',[_vm._v(_vm._s(phone))])],1)}),0)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.min_width_mail},[(item.email)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.email))])],1)]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [(item.website !== null && item.website !== '')?_c('div',{class:_vm.$style.min_width},[_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.website)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]),_c('span',[_vm._v(_vm._s(item.website))])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.actionBtns},[_c('v-btn',{attrs:{"disabled":!_vm.if_roles_confirm,"icon":"","small":"","color":"accent darken-4"},on:{"click":function($event){return _vm.edit_point(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":!_vm.if_roles_confirm,"icon":"","small":"","color":"secondary darken-4"},on:{"click":function($event){return _vm.del_point(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}}],null,true)}),_c('NewSalePointDialog',{attrs:{"sale_point":_vm.editable_point},on:{"submit":_vm.add_or_edit,"close":_vm.close_new_point_dialog},model:{value:(_vm.new_point_dialog),callback:function ($$v) {_vm.new_point_dialog=$$v},expression:"new_point_dialog"}}),_c('ConfirmDialog',{ref:"confirm"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }